import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { head } from 'lodash'
import parse from 'html-react-parser';
import Banners from '../components/Banners'
import Contact from '../components/Contact'

export const AboutPageTemplate = ({ content, contentComponent, frontmatter }) => {
  const PageContent = contentComponent || Content
  const bannerImgSrc = frontmatter.bannerImg.childImageSharp.fluid
  return (
    <>
      <section className="testimonial-wrap">
        <div className="container">
          <blockquote className="testimonial">
            {parse(frontmatter.testimonial)}
          </blockquote>
        </div>
      </section>

      <section
        className="section"
      >
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-5">
            {parse(frontmatter.introText)}
            </div>
            <div className="column is-6 is-offset-1">
              <div className="content">
                <h2 className="title is-2">Get in touch</h2>
                {parse(frontmatter.contactText)}
                <Link to="/contact" className="button is-large is-primary">
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      <Img style="" fluid={bannerImgSrc} alt="3 Point Turn" />
      
      <Banners bannerItems={frontmatter.banners} />
      <Contact />
    </>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        frontmatter={post.frontmatter}
        content={post.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        bannerImg {
          childImageSharp {
            fluid(quality: 60, maxWidth: 2500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        testimonial
        introText
        contactText
        banners {
          heading
          description
          link
          bgcolour
          classwrap
          image {
            childImageSharp {
              fluid(quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
