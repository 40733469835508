import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
// import PreviewCompatibleImage from './PreviewCompatibleImage'
import Img from 'gatsby-image'
import parse from 'html-react-parser';


const Banners = ({ bannerItems }) => {
  return bannerItems.map(item => (
    <div>
      {item.testimonial &&
      <section className="testimonial-wrap">
        <div className="container">
          <blockquote className="testimonial">
            {parse(item.testimonial)}
          </blockquote>
        </div>
      </section>
      }
      <section
        className="section"
        style={{
          backgroundColor: `${item.bgcolour}`,
        }}>
        <div className="container">
          <div
            key={item.heading}
            className="banner-wrapper"
          >
            <div className={"columns is-vcentered " + item.classwrap}>
              <div className="column is-6">
              <Img style="" fluid={item.image.childImageSharp.fluid} alt="Richard Watson" />
              </div>
              <div className="column is-6">
                <div className="content">
                  <h2 className="title is-3">{item.heading}</h2>
                  {parse(item.description)}
                  <Link to={item.link} className="button is-primary">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    ))
}


export default Banners
